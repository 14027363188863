<template>
    <v-list class="gst-list pt-0">
        <v-list-item
            v-for="(item, index) in items"
            :key="index"
            class="gst-list__item px-0 py-4"
            :to="{ name: 'event', params: { id: item.id } }">
            <EventsSearchByItem
                :with-price-icon="true"
                :event="item"
                :content-max-lines="2"
                :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                class="u-background-transparent u-width-100" />
        </v-list-item>
    </v-list>
</template>

<script>
    import { mapGetters } from 'vuex';
    import EventsSearchByItem from '@tenant/app/components/event/EventsSearchByItem.vue';

    export default {
        name: 'SearchResultTabsEventsList',
        components: {
            EventsSearchByItem
        },
        props: {
            items: {
                type: Array,
                default: null
            }
        },
        computed: {
            ...mapGetters( {
                isLoyaltyCurrencyTypeMoney: 'appTenant/isLoyaltyCurrencyTypeMoney'
            } ),
        }
    };
</script>